<template>
  <div class="echart" v-loading="loading">
    <div class="bar" :style="{height:height}">
      <vue-echarts :option="options" style="height: 100%; width: 98%;" ref="chart" />
    </div>
  </div>
</template>
<script>
import { VueEcharts } from 'vue3-echarts'
import { getJobSummaryCompletedBarges } from '@/services/modules'

export default {
  name: "TotalBargesLoadedBar",
  components: {
    VueEcharts,
  },
  props: {
    startDate: {
      type: String
    },
    endDate: {
      type: String
    },
    mock: {
      type: Boolean,
      default: false,
    }
  },
  async created() {
    await this.getData()
  },
  data() {
    return {
      loading: false,
      titleText: '',
      yAxisData: [],
      seriesData: [],
      disableZoom: true,
    };
  },
  methods: {
    async getData() {
      this.loading = true
      const res = await getJobSummaryCompletedBarges(this.startDate, this.endDate, this.mock, 'asc')
      this.yAxisData = []
      this.seriesData = []
      let array = res ?? []
      array.map(item => {
        this.yAxisData.push(item.bargeName)
        this.seriesData.push(item.quantity)
      })
      this.titleText = this.seriesData.length
    //  this.disableZoom = ! ( this.isLargeItem() || this.isLargeScale() )
      this.loading = false
    },
    isLargeItem() {
      return this.yAxisData.length > 10
    },
    isLargeScale() {
      if(this.seriesData.length > 1) {
        const highiest = Math.max(...this.seriesData)
        const lowest = Math.min(...this.seriesData)
        const range = Math.abs(Math.round(highiest - lowest))
        const size = 30
        return range ? Math.abs((lowest / range * 100)) < size : false
      } 
      return false
    },
  },
  computed: {
    height() {
      let height = 300
      if(this.yAxisData.length > 10) {
        height += (this.yAxisData.length - 10) * 20;
      } 
      return `${height}px`
    },
    options() {
      return {
        textStyle: {
          fontFamily: 'Inter, "Helvetica Neue", Arial, sans-serif',
        },
        title: {
          text: this.titleText,
          subtext: 'Total Barge Loaded',
          left: 'left',
          show: true,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        yAxis: {
          type: 'category',
          data: this.yAxisData,
          axisTick: {
            alignWithLabel: true
          }
        },
        xAxis: {
          type: 'value',
          splitNumber: 5,
        },
        dataZoom: this.disableZoom ? null : [
          {
            type: 'slider',
            yAxisIndex: 0,
            zoomLock: true,
            width: 5,
            right: 10,
            top: '10%',
            bottom: '10%',
            start: 70,
            end: 20,
            brushSelect: false,
            handleSize: '60%',
            showDetail: false,
            backgroundColor: '#000',
            borderColor: '#000',
            opacity: 1,
            brushStyle: false,
            handleStyle: {
              color: '#FF6700',
              borderColor: '#FF6700'
            }
          },
          {
            type: 'inside',
            id: 'insideY',
            yAxisIndex: 0,
            start: 15,
            end: 20,
            zoomOnMouseWheel: false,
            moveOnMouseMove: true,
            moveOnMouseWheel: true,
            backgroundColor: 'transparent'
          }
        ],
        series: [
          {
            name: 'Direct',
            data: this.seriesData,
            barWidth: '20%',
            barMinWidth: '10px',
            barCategoryGap: 10,
            type: 'bar',
            itemStyle: {
              color: function (param) {
                return '#5E47DA';
              }
            },
          }
        ]
      }
    }
  }
}
</script>
<style scoped>
.echart {
  width: 100%;
  height: 325px; 
  overflow-y: auto;
  scrollbar-width: thin;
}
.bar {
  max-width: 100%;
  background-color: white; 
  border: 1px solid #E5E5E5; 
  border-radius: 10px; 
  padding: 10px;
}
</style>